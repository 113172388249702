<template>
  <div class="cart">
    <page-nav title="购物车" />
    <div class="cart-list" v-if="list.length > 0">
      <div class="cart-item" v-for="item in list" :key="item.storeId">
        <div class="head-wrap">
          <van-checkbox v-model="item.checked" class="checkbox" @click="onChangeStore(item)"></van-checkbox>
          <div class="storeName van-ellipsis">{{item.storeTitle}}&nbsp;></div>
        </div>
        <div class="goods-wrap">
          <div class="cart-good" v-for="good in item.shopCartList" :key="good.productId">
            <div class="good-info">
              <van-checkbox v-model="good.checked" class="checkbox" @click="onChangeGood"></van-checkbox>
              <img class="cover" :src="good.titleImg" @click="onClickGood(good)" />
              <div class="info" @click="onClickGood(good)">
                <div class="name van-multi-ellipsis--l2">{{good.title}}</div>
                <div class="tag" v-if="good.commission > 0">
                  <van-tag plain type="primary" color="#ff0069">激励宝</van-tag>
                </div>
                <div class="price-info">
                  <div class="price">
                    ￥<span class="num">{{good.price}}</span>
                  </div>
                  <div class="count">x{{good.amount}}</div>
                </div>
              </div>
            </div>
            <div class="action">
              <van-button type="default" size="small" @click="onDeleteCartItem(good)">删除</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-if="!loading && list.length == 0" description="购物车是空的~" />
    <div class="fix-bar">
      <div class="cart-bar">
        <van-checkbox class="checkAll" v-model="checkAll" @click="onCheckAll">全选</van-checkbox>
        <div class="total">
          总计:<span class="count">￥{{totalPrice}}</span>
        </div>
        <van-button :disabled="checkList.length == 0" class="submit" type="primary" color="#ff0069" round @click="onSubmit">去结算({{checkList.length}}件)</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Checkbox, Lazyload, Toast, Tag, Dialog } from 'vant';
import PageNav from '../components/PageNav.vue';
import Fetch from '@/common/fetch';
import { GetCartList, DeleteCart } from '@/common/interface';

Vue.use(Checkbox);
Vue.use(Lazyload);
Vue.use(Toast);
Vue.use(Tag);
Vue.use(Dialog);

export default {
  name: 'Cart',
  components: { PageNav },
  data() {
    return {
      list: [],
      loading: true,
      checkList: [],
      checkAll: false,
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.checkList.forEach(item => {
        total += parseFloat(item.price) * item.amount;
      })
      return total;
    },
  },
  created() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      Fetch.post(GetCartList).then((res) => {
        const list = res || [];
        list.forEach(store => {
          store.checked = false;
          store.shopCartList.forEach(good => {
            good.checked = false;
          })
        });
        this.list = list;
        this.loading = false;
      }).catch((err) => {
        Toast.fail(err.msg || '请求失败');
      });
    },

    onChangeStore(changedStore) {
      let checkAll = true;
      let checkList = [];
      const list = this.list;
      for (let index = 0; index < list.length; index++) {
        const store = list[index];
        for (let index = 0; index < store.shopCartList.length; index++) {
          const good = store.shopCartList[index];
          if (store.storeId == changedStore.storeId) {
            good.checked = store.checked;
          }
          if (good.checked) {
            checkList.push(good);
          }
        }
        checkAll = checkAll && store.checked;
      }
      this.list = list;
      this.checkList = checkList;
      this.checkAll = checkAll;

    },

    onChangeGood() {
      let checkAll = true;
      let checkList = [];
      const list = this.list;
      for (let index = 0; index < list.length; index++) {
        const store = list[index];
        let storeCheck = true;
        for (let index = 0; index < store.shopCartList.length; index++) {
          const good = store.shopCartList[index];
          if (good.checked) {
            checkList.push(good);
          } else {
            storeCheck = false;
          }
        }
        store.checked = storeCheck;
        checkAll = checkAll && store.checked;
      }
      this.list = list;
      this.checkList = checkList;
      this.checkAll = checkAll;
    },

    onCheckAll() {
      let checkList = [];
      const list = this.list;
      for (let index = 0; index < list.length; index++) {
        const store = list[index];
        store.checked = this.checkAll;
        for (let index = 0; index < store.shopCartList.length; index++) {
          const good = store.shopCartList[index];
          good.checked = this.checkAll;
          if (good.checked) {
            checkList.push(good);
          }
        }
      }
      this.list = list;
      this.checkList = checkList;
    },

    onClickGood(good) {
      this.$router.push('/product?id=' + good.productId);
    },

    onDeleteCartItem(deleteItem) {
      Dialog.confirm({
        title: '确认要删除此商品？',
        confirmButtonText: '删除',
      })
      .then(() => {
        this.fetchDeleteItem(deleteItem);
      })
    },

    fetchDeleteItem(deleteItem) {
      const params = {
        ids: deleteItem.id,
      };
      Fetch.post(DeleteCart, params).then(() => {
        let checkAll = true;
        let checkList = [];
        const list = this.list;
        let emptyIndex = -1;
        for (let index = 0; index < list.length; index++) {
          const store = list[index];
          let storeCheck = true;
          let deleteIndex = -1;
          for (let index = 0; index < store.shopCartList.length; index++) {
            const good = store.shopCartList[index];
            if (good.id == deleteItem.id) {
              deleteIndex = index;
            } else {
              if (good.checked) {
                checkList.push(good);
              } else {
                storeCheck = false;
              }
            }
          }

          store.checked = storeCheck;
          if (deleteIndex >= 0) {
            store.shopCartList.splice(deleteIndex, 1);
          }
          checkAll = checkAll && store.checked;
          if (store.shopCartList.length == 0) {
            emptyIndex = index;
          }
        }
        if (emptyIndex >= 0) {
          list.splice(emptyIndex, 1);
        }
        this.list = list;
        this.checkList = checkList;
        this.checkAll = checkAll;

      }).catch((err) => {
        Toast.fail(err.msg || '请求失败');
      });
    },

    onSubmit() {
      Dialog.alert({
        title: '请您在电脑端进行下单操作',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cart-list {
  padding-bottom: 60px;
  .cart-item {
    position: relative;
    margin: 5px 0;
    background: #fff;
    padding: 5px 0;
    font-size: 14px;
    .head-wrap {
      position: sticky;
      top: 0;
      line-height: 30px;
      display: flex;
      align-items: center;
      background: #fff;
      .checkbox {
        height: 30px;
        padding: 0 15px;
      }
      .storeName {
        font-weight: bold;
        padding-right: 15px;
      }
    }
    .goods-wrap {
      .cart-good {
        padding: 8px 0;
      }
      .good-info {
        display: flex;
        .checkbox {
          height: 100px;
          padding: 0 15px;
        }
        .cover {
          width: 100px;
          height: 100px;
          flex-shrink: 0;
        }
        .info {
          padding: 0 10px;
          flex: 1;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .name {
            height: 35px;
            font-size: 13px;
            margin-bottom: 6px;
          }
          .price-info {
            display: flex;
            justify-content: space-between;
            .price {
              flex: 1;
              color: #ff0069;
              font-weight: bold;
              .num {
                font-size: 16px;
              }
            }
            .count {
              color: #666;
              font-size: 14px;
            }
          }
        }
      }
      .action {
        text-align: right;
        padding: 0 10px;
      }
    }
  }
}

.fix-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 120;
  background: #fff;
}
.cart-bar {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 80px;
  padding-right: 10px;
  .checkAll {
    position: absolute;
    left: 15px;
    top: 0;
    height: 50px;
    font-size: 15px;
  }
  .total {
    font-size: 16px;
    margin-right: 10px;
    .count {
      font-weight: bold;
    }
  }
  .submit {
    width: 115px;
  }

}
</style>